import React from 'react';
import { NavLink } from 'cccisd-react-router';
import IconLeft from 'cccisd-icons/arrow-left8';

const Appdefs = window.cccisd.appDefs;

const GoHomeButton = () => {
    let goHomeRoute;
    try {
        goHomeRoute = Appdefs.routes.find(r => Appdefs.navs[0].homepage === r.handle).url;
    } catch (e) {
        goHomeRoute = '/welcome';
    }

    return (
        <div style={{ marginBottom: 20 }}>
            <button type="button" className="btn btn-link">
                <NavLink to={goHomeRoute}>
                    <IconLeft spaceRight />
                    Go To Course
                </NavLink>
            </button>
        </div>
    );
};

export default GoHomeButton;
