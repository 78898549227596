import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page } from 'cccisd-laravel-report';
import style from './style.css';
import header from './header.svg';
import footer from './footer.svg';
import scan from './scan.png';
import anchor from './anchor.png';
import queryPawn from './learnerPawnList.graphql';
import queryHash from './learnerHashList.graphql';
import { client } from 'cccisd-apollo';
import _get from 'lodash/get';
import fightIcon from './fightIcon.png';
import flightIcon from './flightIcon.png';
import freezeIcon from './freezeIcon.png';
import Loader from 'cccisd-loader';
import { useParams } from 'react-router-dom';

const Fortress = window.cccisd.fortress;

const responseFreezeMap = {
    freeze: { text: 'Freeze', image: freezeIcon },
};
const responseFightMap = {
    fight: { text: 'Fight', image: fightIcon },
};
const responseFlightMap = {
    flight: { text: 'Flight', image: flightIcon },
};

const warningSignsTextMap = {
    warning_blank: 'Mind goes blank',
    warning_breathing_slow: 'Heartbeat or breathing slow',
    warning_cold: 'Feeling cold or numb',
    warning_eyes_wide: 'Eyes widen',
    warning_knots: 'Knots in the stomach/nausea',
    warning_limbs: 'Limbs feel stuck, heavy, or stiff',
    warning_pupils: 'Dilated Pupils',
    warning_rapid_heartbeat: 'Rapid heartbeat and rapid breathing',
    warning_restless: 'Restless arms or legs',
    warning_skin_change: 'Skin Changes',
    warning_tight: 'Tightened jaw or fists',
    warning_tremble: 'Trembling or Shaking',
};

const strategyMap = {
    body_scan: { text: 'Body scan', image: scan },
    anchor_drop: { text: 'Dropping the anchor', image: anchor },
};

const situationsTextMap = {
    trigger_bad_grade: 'Getting a bad grade',
    trigger_big_crowds: 'Being in big crowds',
    trigger_exam_test: 'Worrying about an exam/test',
    trigger_face_fear: 'Facing a fear',
    trigger_input_answer: '',
    trigger_lonely: 'Feeling lonely or left-out',
    trigger_others_opinions: 'Worrying what other people think about me',
    trigger_overwhelmed: 'Feeling overwhelmed about school',
    trigger_presentation: 'Giving a presentation or performance',
    trigger_sleep: 'Trying to sleep',
    trigger_small_space: 'Being in small spaces',
    trigger_strangers: 'Talking to strangers',
};

const dummyData = {
    type_response: 'Freeze',
    hardest_emotion: 'scared',

    warning_blank: 'unchecked',
    warning_breathing_slow: 'checked',
    warning_cold: 'checked',
    warning_eyes_wide: 'unchecked',
    warning_knots: 'checked',
    warning_limbs: 'unchecked',
    warning_pupils: 'unchecked',
    warning_rapid_heartbeat: 'unchecked',
    warning_restless: 'unchecked',
    warning_skin_change: 'unchecked',
    warning_tight: 'unchecked',
    warning_tremble: 'unchecked',

    trigger_bad_grade: 'unchecked',
    trigger_big_crowds: 'unchecked',
    trigger_exam_test: 'unchecked',
    trigger_face_fear: 'unchecked',
    trigger_lonely: 'checked',
    trigger_others_opinions: 'unchecked',
    trigger_overwhelmed: 'unchecked',
    trigger_presentation: 'unchecked',
    trigger_sleep: 'unchecked',
    trigger_small_space: 'unchecked',
    trigger_strangers: 'unchecked',
    trigger_input_answer: 'checked',
    trigger_input: 'When my alarm goes off',

    body_scan: 'checked',
    anchor_drop: 'checked',

    person_to_help_parent: 'Friend',
    person_to_help_friend: 'Chris',
    hold_back_from_skill: 'Other',
    hold_back_from_skill_input: 'being too frustrated',
    overcome_obstacle: 'other',
    overcome_obstacle_input: 'breathing deeply until the frustrating goes away',
};

export const SSIActionPlanModule4 = props => {
    const { isPreview } = props;
    const [data, setData] = useState(null);
    const params = useParams();

    useEffect(() => {
        async function getSsiData() {
            const response = await client.query({
                query: params.shortHash ? queryHash : queryPawn,
                variables: {
                    pawnId: Fortress.user.acting.id,
                    deploymentHash: params.deploymentHash,
                    respondentHash: params.shortHash,
                },
                fetchPolicy: 'network-only',
            });
            const ssiData = isPreview ? dummyData : _get(response, 'data.roles.anyRole.assignmentProgress.devTags', {});
            setData(ssiData);
        }
        getSsiData();
    }, []);

    if (!data) {
        return <Loader loading type="inline" />;
    }
    const feel = data.type_response.toLowerCase();
    const emotions = data.hardest_emotion.toLowerCase();

    let situationsAnswerBlock = [];

    Object.keys(situationsTextMap).forEach(variable => {
        if (situationsAnswerBlock.length === 2) {
            return;
        }
        if (data[variable] === 'checked') {
            if (variable === 'trigger_input_answer') {
                situationsAnswerBlock.push(
                    !data.trigger_input
                        ? 'Other'
                        : data.trigger_input[0].toUpperCase() + data.trigger_input.slice(1).toLowerCase()
                );
            } else {
                situationsAnswerBlock.push(situationsTextMap[variable]);
            }
        }
    });

    let brother = '';
    let sister = '';
    let friend = '';
    let therapist = '';
    let someone = '';
    if (data.person_to_help_parent === 'Brother') {
        brother = data.person_to_help_brother || '';
    }
    if (data.person_to_help_parent === 'Sister') {
        sister = data.person_to_help_sister || '';
    }
    if (data.person_to_help_parent === 'Friend') {
        friend = data.person_to_help_friend || '';
    }
    if (data.person_to_help_parent === 'Therapist') {
        therapist = data.person_to_help_therapist || '';
    }
    if (data.person_parent === 'Someone else') {
        someone = data.person_text || '';
    }

    const family = [
        brother.charAt(0).toUpperCase() + brother.slice(1).toLowerCase(),
        sister.charAt(0).toUpperCase() + sister.slice(1).toLowerCase(),
        friend.charAt(0).toUpperCase() + friend.slice(1).toLowerCase(),
        therapist.charAt(0).toUpperCase() + therapist.slice(1).toLowerCase(),
    ];

    let famResults = [];
    family.forEach(element => {
        if (element !== null && element !== '') {
            famResults.push(element);
        }
    });

    return (
        <div style={{ maxWidth: '75vw' }}>
            <Report downloadFilename={`My Action Plan - ${data.type_response}`}>
                <Page>
                    <div style={{ maxWidth: '100%' }}>
                        <div className={style.pageWrapper}>
                            <header className={style.header}>
                                <div className={style.headerLogo}>
                                    <img src={header} alt="Practice" />
                                </div>
                            </header>
                            <div>
                                <div className={style.testRun}>
                                    <div className={style.sectionOff}>
                                        {Object.keys(responseFreezeMap).map(variable => {
                                            if (data.type_response === 'Freeze') {
                                                return (
                                                    <div className={style.firstFreezeBox} key={variable}>
                                                        <img
                                                            src={responseFreezeMap.freeze.image}
                                                            className={style.triggerIcon}
                                                            alt="freeze"
                                                        />
                                                        <div className={style.help}>
                                                            When I feel <b>{emotions}</b>, my brain tends to trigger a{' '}
                                                            <b>{feel}</b> response.
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                        {Object.keys(responseFlightMap).map(variable => {
                                            if (data.type_response === 'Flight') {
                                                return (
                                                    <div className={style.firstFlightBox} key={variable}>
                                                        <img
                                                            src={responseFlightMap.flight.image}
                                                            className={style.triggerIcon}
                                                            alt="flight"
                                                        />
                                                        <div className={style.help}>
                                                            When I feel <b>{emotions}</b>, my brain tends to trigger a{' '}
                                                            <b>{feel}</b> response.
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                        {Object.keys(responseFightMap).map(variable => {
                                            if (data.type_response === 'Fight') {
                                                return (
                                                    <div className={style.firstFightBox} key={variable}>
                                                        <img
                                                            src={responseFightMap.fight.image}
                                                            className={style.triggerIcon}
                                                            style={{ borderColor: '#bd4b4a' }}
                                                            alt="fight"
                                                        />
                                                        <div className={style.help}>
                                                            When I feel <b>{emotions}</b>, my brain tends to trigger a{' '}
                                                            <b>{feel}</b> response.
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                </div>
                                <div className={style.sectionOff}>
                                    <p>
                                        The top 3 warning signs that I am having a <b>{feel}</b> response are:
                                    </p>

                                    <div className={style.testRun2}>
                                        {Object.keys(warningSignsTextMap).map(variable => {
                                            if (data[variable] === 'checked') {
                                                return (
                                                    <div className={style.smallBox} key={variable}>
                                                        {warningSignsTextMap[variable]}
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                </div>
                                <div className={style.sectionOff}>
                                    <p>
                                        Two situations that tend to trigger my <b>{feel}</b> response are:
                                    </p>
                                    <div className={style.testRun2}>
                                        {situationsAnswerBlock.map(answer => (
                                            <div className={style.longBox} key={answer}>
                                                {answer}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className={style.sectionOff}>
                                    <div className={style.testRun}>
                                        {Object.keys(strategyMap).map(variable => {
                                            if (data[variable] === 'checked') {
                                                return (
                                                    <img
                                                        src={strategyMap[variable].image}
                                                        className={style.skillsBox1}
                                                        alt="images"
                                                        key={variable}
                                                    />
                                                );
                                            }
                                            return null;
                                        })}

                                        <div className={style.lightBox}>
                                            Skill(s) I can use:{' '}
                                            {Object.keys(strategyMap).map(variable => {
                                                if (data[variable] === 'checked') {
                                                    return (
                                                        <li key={variable}>
                                                            {' '}
                                                            <b>{strategyMap[variable].text}</b>
                                                        </li>
                                                    );
                                                }
                                                return null;
                                            })}{' '}
                                        </div>
                                    </div>
                                </div>

                                <div className={style.sectionOff}>
                                    <p>
                                        One person who can help me remember to use my skill(s) is{' '}
                                        <b>
                                            {((data.person_to_help_parent === 'Someone else' && someone === '') ||
                                            someone === null
                                                ? 'someone else'
                                                : someone.charAt(0).toUpperCase() + someone.slice(1).toLowerCase()) ||
                                                (data.person_to_help_parent === 'Mom' ||
                                                data.person_to_help_parent === 'Dad' ||
                                                famResults < 1
                                                    ? 'my ' + data.person_to_help_parent.toLowerCase()
                                                    : 'my ' +
                                                      data.person_to_help_parent
                                                          .replace('Someone else', '')
                                                          .toLowerCase() +
                                                      ' ' +
                                                      famResults)}
                                        </b>
                                        .
                                    </p>
                                </div>
                                <p>
                                    If I face an inner obstacle to using my skill(s), like{' '}
                                    <b>
                                        {data.hold_back_from_skill.toLowerCase() === 'other'
                                            ? data.hold_back_from_skill_input
                                            : data.hold_back_from_skill[0].toLowerCase() +
                                              data.hold_back_from_skill.slice(1)}
                                    </b>
                                    , then I will try to help myself by{' '}
                                    <b>
                                        {data.overcome_obstacle.toLowerCase() === 'other'
                                            ? data.overcome_obstacle_input
                                            : data.overcome_obstacle[0].toLowerCase() + data.overcome_obstacle.slice(1)}
                                    </b>
                                    .
                                </p>
                                <div className={style.sectionOff}>
                                    <p>IF YOU ARE FEELING IN CRISIS, UNSAFE, or SUICIDAL:</p>
                                </div>
                                <div className={style.testRun2}>
                                    <div className={style.box}>
                                        Call the National Suicide Prevention Lifeline:
                                        <div className={style.fontChange}>(800) 273-8255</div>
                                    </div>

                                    <div className={style.random}>or</div>

                                    <div className={style.box}>
                                        Text Crisis Text Line:{' '}
                                        <div className={style.fontChange}>Text HOME to 741741</div>
                                    </div>
                                </div>
                            </div>

                            <footer>
                                <div className={style.footerLogo}>
                                    <img src={footer} alt="Practice" />
                                </div>
                            </footer>
                        </div>
                    </div>
                </Page>
            </Report>
        </div>
    );
};

SSIActionPlanModule4.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets: [],
    getFilterFields: props => [],
})(SSIActionPlanModule4);
