import React from 'react';
import { Field, CccisdInput } from 'cccisd-formik';
import { reportTemplateBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    reportTitle: 'My Action Plan',
    widgets: [],
};

const SSIActionPlanModule6 = () => {
    return (
        <>
            <Field name="reportTitle" label="Report Title" component={CccisdInput} />
        </>
    );
};

export default reportTemplateBuilder({ widgets: [] })(SSIActionPlanModule6);
