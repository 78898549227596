import React from 'react';
import GoHomeButton from '../components/GoHomeButton';
import { SSIActionPlanModule4 } from '../vendor/reports/reportTemplates/SSIActionPlanReport/Player';

const Report1 = () => {
    return (
        <div>
            <GoHomeButton />
            <SSIActionPlanModule4 />
        </div>
    );
};

export default Report1;
