import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'SSIActionPlanModule6',
    label: 'SSI Action Plan Report - Module 6',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
