import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page } from 'cccisd-laravel-report';
import style from './style.css';
import queryHash from './learnerHashList2.graphql';
import queryPawn from './learnerPawnList2.graphql';
import { client } from 'cccisd-apollo';
import _get from 'lodash/get';
import footer from './footer.svg';
import phone from './phone.svg';
import header from './header.svg';
import see from './see.svg';
import touch from './touch.svg';
import taste from './taste.svg';
import smell from './smell.svg';
import hear from './hear.svg';
import Loader from 'cccisd-loader';
import { useParams } from 'react-router-dom';

const Fortress = window.cccisd.fortress;

let sensesTextMap = {
    senses_feel: { text: 'feel', image: touch },
    senses_hear: { text: 'hear', image: hear },
    senses_see: { text: 'see', image: see },
    senses_smell: { text: 'smell', image: smell },
    senses_taste: { text: 'taste', image: taste },
};

const unhelpfulTextMap = {
    self_talk_sad: 'It makes me too sad to act.',
    self_talk_mad: 'It makes me too angry to act.',
    self_talk_scared: 'It makes me too scared or anxious to act.',
    self_talk_dont_want: 'It tells me to act in ways I don’t want to.',
    self_talk_opposite: 'It tells me to act in ways that are opposite from my goals.',
    self_talk_evidence: 'There is no evidence that the self-talk is really true.',
    self_talk_fill: '',
};

const activityTextMap = {
    feel_peaceful: 'peaceful',
    feel_happy: 'happy',
    feel_calm: 'calm',
    feel_confident: 'confident',
    feel_curious: 'curious',
    feel_rested: 'rested',
    feel_interested: 'interested',
    feel_excited: 'excited',
    feel_motivated: 'motivated',
    feel_grateful: 'grateful',
    feel_hopeful: 'hopeful',
    feel_: '',
};

const manageSelfTextMap = {
    swipe: 'Swipe away to the next thought',
    scroll: 'Scroll away to free up thinking space',
};

const dummyData = {
    self_talk_sad: 'unchecked',
    self_talk_mad: 'unchecked',
    self_talk_scared: 'checked',
    self_talk_dont_want: 'checked',
    self_talk_opposite: 'unchecked',
    self_talk_evidence: 'checked',
    self_talk_text: 'Call my friends to talk about sports',

    swipe: 'checked',
    scroll: 'unchecked',

    senses_feel: 'unchecked',
    senses_hear: 'checked',
    senses_see: 'unchecked',
    senses_smell: 'checked',
    senses_taste: 'checked',

    feel_peaceful: 'checked',
    feel_happy: 'unchecked',
    feel_calm: 'unchecked',
    feel_confident: 'unchecked',
    feel_curious: 'checked',
    feel_rested: 'unchecked',
    feel_interested: 'unchecked',
    feel_excited: 'unchecked',
    feel_motivated: 'unchecked',
    feel_grateful: 'unchecked',
    feel_hopeful: 'unchecked',
    feel_: 'unchecked',

    person_parent: 'sister',
    what_is_it: 'other',
    what_is_it_text: 'being afraid',
    inner_obstacle: 'other',
    inner_obstacle_text: 'using self talk strategies',
};

export const SSIActionPlanModule6 = props => {
    const { isPreview } = props;
    const [data, setData] = useState(null);
    const params = useParams();

    useEffect(() => {
        async function getNewData() {
            const response = await client.query({
                query: params.shortHash ? queryHash : queryPawn,
                variables: {
                    pawnId: Fortress.user.acting.id,
                    deploymentHash: params.deploymentHash,
                    respondentHash: params.shortHash,
                },
                fetchPolicy: 'network-only',
            });
            const newData = isPreview ? dummyData : _get(response, 'data.roles.anyRole.assignmentProgress.devTags', {});
            setData(newData);
        }
        getNewData();
    }, []);

    if (!data) {
        return <Loader loading type="inline" />;
    }

    let unhelpfulAnswerBlock = [];

    Object.keys(unhelpfulTextMap).forEach(variable => {
        if (unhelpfulAnswerBlock.length === 3) {
            return;
        }
        if (data[variable] === 'checked') {
            if (variable === 'self_talk_fill') {
                unhelpfulAnswerBlock.push(
                    !data.self_talk_text
                        ? 'Other'
                        : data.self_talk_text[0].toUpperCase() + data.self_talk_text.slice(1).toLowerCase()
                );
            } else {
                unhelpfulAnswerBlock.push(unhelpfulTextMap[variable]);
            }
        }
    });

    let brother = '';
    let sister = '';
    let friend = '';
    let therapist = '';
    let someone = '';
    if (data.person_parent === 'Brother') {
        brother = data.person_brother || '';
    }
    if (data.person_parent === 'Sister') {
        sister = data.person_sister || '';
    }
    if (data.person_parent === 'Friend') {
        friend = data.person_friend || '';
    }
    if (data.person_parent === 'Therapist') {
        therapist = data.person_therapist || '';
    }
    if (data.person_parent === 'Someone else') {
        someone = data.person_text || '';
    }

    const person = [
        sister.charAt(0).toUpperCase() + sister.slice(1).toLowerCase(),
        brother.charAt(0).toUpperCase() + brother.slice(1).toLowerCase(),
        friend.charAt(0).toUpperCase() + friend.slice(1).toLowerCase(),
        therapist.charAt(0).toUpperCase() + therapist.slice(1).toLowerCase(),
    ];
    let personResults = [];
    person.forEach(element => {
        if (element !== null && element !== '') {
            personResults.push(element);
        }
    });

    let activity1 = '';
    let activity2 = '';
    let activity3 = '';
    let activity4 = 'other';

    Object.keys(activityTextMap).forEach(variable => {
        if (data[variable] === 'checked') {
            if (variable === 'feel_') {
                activity3 = data.feel_text;
            }
            if (activity1 === '') {
                activity1 = activityTextMap[variable];
            } else {
                activity2 === '';
                activity2 = activityTextMap[variable];
            }
        }
    });
    const activity5 = data.feel_text === null || data.feel_text === '' ? activity4 : activity3.toLowerCase();

    let sense1 = '';
    let sense2 = '';
    let sense3 = '';

    Object.keys(sensesTextMap).forEach(variable => {
        if (data[variable] === 'checked') {
            if (sense1 === '') {
                sense1 = sensesTextMap[variable].text;
            } else if (sense2 === '') {
                sense2 = sensesTextMap[variable].text;
            } else if (sense3 === '') {
                sense3 = sensesTextMap[variable].text;
            }
        }
    });

    return (
        <div style={{ maxWidth: '75vw' }}>
            <Report downloadFilename="My Action Plan - Self Talk">
                <Page>
                    <div style={{ maxWidth: '100%' }}>
                        <div className={style.pageWrapper}>
                            <div>
                                <header className={style.header}>
                                    <div className={style.headerLogo}>
                                        <img src={header} alt="Practice" />
                                    </div>
                                </header>
                                <div>
                                    <div className={style.sectionOff}>
                                        The top 3 signs that my self-talk is unhelpful are:
                                    </div>

                                    <div className={style.testRun2}>
                                        {unhelpfulAnswerBlock.map(answer => (
                                            <div className={style.smallBox} key={answer}>
                                                {answer}
                                            </div>
                                        ))}
                                    </div>

                                    <div className={style.sectionOff}>
                                        <div className={style.testRun}>
                                            <img src={phone} className={style.phoneBox} alt="phone" />

                                            <div className={style.firstBox}>
                                                When my self-talk is unhelpful, my go-to strategy for letting it go is
                                                to:{' '}
                                                {Object.keys(manageSelfTextMap).map(variable => {
                                                    if (data[variable] === 'checked') {
                                                        return (
                                                            <li key={variable}>
                                                                <b> {manageSelfTextMap[variable]} </b>
                                                            </li>
                                                        );
                                                    }
                                                    return null;
                                                })}{' '}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.sectionOff}>
                                        <div className={style.testRun3}>
                                            <div className={style.lightBox}>
                                                <div className={style.sensesImages}>
                                                    {Object.keys(sensesTextMap).map(variable => {
                                                        if (data[variable] === 'checked') {
                                                            return (
                                                                <img
                                                                    src={sensesTextMap[variable].image}
                                                                    className={style.senses}
                                                                    alt="images"
                                                                    key={variable}
                                                                />
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </div>
                                                <div className={style.grounded}>
                                                    If this is hard to do, I can get grounded by noticing my five
                                                    senses, especially noticing things I can <b>{sense1}</b>,{' '}
                                                    <b>{sense2}</b>, and <b>{sense3}</b>.
                                                    <p>
                                                        This will help me feel more <b>{activity1}</b> and{' '}
                                                        <b>{activity2 === '' ? activity5 : activity2}</b>.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p>
                                        One person who can help me remember to use these skills is{' '}
                                        <b>
                                            {((data.person_parent === 'Someone else' && someone === '') ||
                                            someone === null
                                                ? 'someone else'
                                                : someone.charAt(0).toUpperCase() + someone.slice(1).toLowerCase()) ||
                                                (data.person_parent === 'Mom' ||
                                                data.person_parent === 'Dad' ||
                                                personResults < 1
                                                    ? 'my ' + data.person_parent.toLowerCase()
                                                    : 'my ' +
                                                      data.person_parent.replace('Someone else', '').toLowerCase() +
                                                      ' ' +
                                                      personResults)}
                                        </b>
                                        .
                                    </p>
                                    <p>
                                        If I face an inner obstacle to using this skill, such as{' '}
                                        <b>
                                            {data.what_is_it.toLowerCase() === 'other'
                                                ? data.what_is_it_text
                                                : data.what_is_it[0].toLowerCase() + data.what_is_it.slice(1)}
                                        </b>
                                        , then I will try to help myself by{' '}
                                        <b>
                                            {data.inner_obstacle.toLowerCase() === 'other'
                                                ? data.inner_obstacle_text
                                                : data.inner_obstacle[0].toLowerCase() + data.inner_obstacle.slice(1)}
                                        </b>
                                        .
                                    </p>
                                    <div className={style.sectionOff}>
                                        <p>IF YOU ARE FEELING IN CRISIS, UNSAFE, or SUICIDAL:</p>
                                    </div>
                                    <div className={style.testRun2}>
                                        <div className={style.box}>
                                            Call the National Suicide Prevention Lifeline:
                                            <div className={style.fontChange}>(800) 273-8255</div>
                                        </div>

                                        <div className={style.random}>or</div>

                                        <div className={style.box}>
                                            Text Crisis Text Line:{' '}
                                            <div className={style.fontChange}>Text HOME to 741741</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <footer>
                                <div className={style.footerLogo}>
                                    <img src={footer} alt="Practice" />
                                </div>
                            </footer>
                        </div>
                    </div>
                </Page>
            </Report>
        </div>
    );
};

SSIActionPlanModule6.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets: [],
    getFilterFields: props => [],
})(SSIActionPlanModule6);
