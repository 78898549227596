import React from 'react';
import GoHomeButton from '../components/GoHomeButton';
import { SSIActionPlanModule6 } from '../vendor/reports/reportTemplates/SSIActionPlanModule6/Player';

const Report2 = () => {
    return (
        <div>
            <GoHomeButton />
            <SSIActionPlanModule6 />
        </div>
    );
};

export default Report2;
