import React from 'react';
import { Field, CccisdInput } from 'cccisd-formik';
import { reportTemplateBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    reportTitle: 'My Action Plan',
    widgets: [],
};

const SSIActionPlanReport = () => {
    return (
        <>
            <Field name="reportTitle" label="Report Title" component={CccisdInput} />
        </>
    );
};

// reportTemplateBuilder is a Formik wrapper which combines the Fields in this Component with Filter and Widget Fields.
export default reportTemplateBuilder({ widgets: [] })(SSIActionPlanReport);
